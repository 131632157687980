export enum ApplicationRoutesEnum {
   MAIN_PAGE = 'main-page',
}

export enum MainPageChildRoutesEnum {
   FEATURE_LINKING_TOOL = 'linking-tool',
}

export enum LinkingToolRoutesEnum {
   GRANTS_VS_ORGANISATIONS = 'grants-vs-organisations',
   MANAGE_PROPOSED_IDEAS = 'manage-proposed-ideas',
   PROJECTS_SEEKING_PARTNERS = 'projects-seeking-partners',
   PROJECTS_SEEKING_PARTNERS_ID = 'projects-seeking-partners/:id',
   MANAGE_PROPOSED_IDEAS_DETAIL = 'detail',
   MANAGE_PROPOSED_IDEAS_SEARCH = 'search',
   GRANTS_VS_ORGANISATIONS_DETAIL = 'grant-detail',
   GRANTS_VS_ORGANISATIONS_SEARCH = 'grant-search',
}
